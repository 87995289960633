import React from 'react';
import AboutSite from "../components/aboutSite.tsx"
import OtherProjects from '../components/otherProjects.tsx';

const Extra: React.FC = () => {
    return (
        <div className="bg-blue-950 min-h-screen text-white" style={{ fontFamily: "'Montserrat', sans-serif" }}>
            <div className="flex flex-col items-center justify-center">
                <div className="pt-10 flex items-center">
                    {/* <img className="w-24 h-24" src="/images/rglogo.png" alt="RG Logo" /> */}
                    <h1 className="text-5xl"> RG Portfolio</h1>
                </div>
                <div className="w-6/12 flex flex-col">
                    <div className="pt-5">
                        <AboutSite />
                    </div>
                    <div className="pt-5">
                        <OtherProjects />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Extra;