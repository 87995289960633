import React from "react";

const OtherProjects: React.FC = () => {
    return (
        <>
            <div className="text-2xl font-bold ">Other Projects</div>
            <p className="pt-4">Examples of some other projects include:
            </p>
            <p className="pt-4">Creating a Python GUI program, which integrates with a paid service using an API, 
                to provide additional functionality for Rota Management.
                A demo of the program can be found <strong>here</strong>.
            </p>
            <p className="pt-4">Various Python programs used by companies to automate data cleaning and restructuring of large excel spreadsheets,
                and to build and email data reports regularly to stakeholders.
            </p>
            <p className="pt-4">Building and deploying other <strong>React</strong> and <strong>Svelte</strong> websites 
            with accompanying <strong>Supabase</strong> databases.
            </p>
        </>
    );
};

export default OtherProjects;