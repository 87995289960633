import React from "react";

const AboutSite: React.FC = () => {
    return (
        <>
            <div className="text-2xl font-bold ">About</div>
            <p className="pt-4">This site is a deployed web application hosted on a VPS, utilizing a modern infrastructure stack.
                <strong> <a href="https://traefik.io" target="_blank" rel="noopener noreferrer">
                    <span className="underline">Traefik</span>
                </a> </strong>
                is used as a reverse proxy to handle dynamic routing, SSL/TLS, and load balancing.
                The backend is powered by a
                <strong> <a href="https://litestar.dev/" target="_blank" rel="noopener noreferrer">
                    <span className="underline">Litestar</span>
                </a>
                </strong> server, a Python framework for API and service handling.
            </p>
            <p className="pt-4">
                The application is containerized with
                <strong> <a href="https://docs.docker.com/" target="_blank" rel="noopener noreferrer">
                    <span className="underline">Docker Compose</span>
                </a>
                </strong>, for scalability and ease of deployment.
                The deployment process uses
                <strong> <a href="https://www.ansible.com/" target="_blank" rel="noopener noreferrer">
                    <span className="underline">Ansible</span>
                </a>
                </strong>
                , to allow for provisioning, configuration management, and repeatable deployments.
            </p>
            <p className="pt-4"> The code for this deployment can be found on my <strong>github</strong>.</p>
            <p className="pt-4">This page has been built using <strong>React</strong> 18.3 - the code can be found <strong>here</strong>.</p>
        </>
    );
};

export default AboutSite;