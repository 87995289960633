// Crawling disabled via robots.txt and robots metatag in index.html

import React from 'react';
// import Logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home.tsx';
import Extra from './pages/extra.tsx';
import NotFound from './pages/notfound.tsx';
import Another from './pages/another.tsx';

const App: React.FC = () => {
  return (
    
    <Router>
        <Routes>
          <Route path="/" element={<Extra />} />
          <Route path="/extra" element={<Extra />} />
          <Route path="/another" element={<Another />} /> 
          
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
  );
}

export default App;
