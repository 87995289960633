import React from 'react';

const Another: React.FC = () => {
    return (
        <div>
            <h1>This is Another Page.</h1>
        </div>
    );
}

export default Another;